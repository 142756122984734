<template>
  <div class="row m-0 py-2 doc-row" @click="toggleCompsArea()" @contextmenu.prevent="showContextMenu">
    <div class="col-7">
      <input class="form-check-input me-4" type="checkbox" value="" />

      <div v-if="deleteDocLoading && thisDocDeleteLoading" class="spinner-border spinner-border-sm text-primary me-4"></div>
      <font-awesome-icon v-else icon="file" class="me-4" />

      <span class="doc-titel me-2" @click="getDocComponents">{{ doc.titel }}</span>
    </div>

    <div class="col-1">
      <div class="dropdown">
        <button type="button" class="btn btn-sm py-0 doc-menu-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" @click.stop>
          <font-awesome-icon icon="ellipsis-vertical" class="text-body-tertiary" />
        </button>

        <ul class="dropdown-menu m-0 p-0 rounded-0">
          <li class="dropdown-item" :class="{ disabled: !rechte.dokumentUpdate }" @click.stop="showUpdateModal()">Bearbeiten</li>
          <li class="dropdown-item" :class="{ disabled: !rechte.dokumentCreate }" @click.stop="showAddCompModal()">Componente hinzufügen</li>
          <li class="dropdown-item" :class="{ disabled: !rechte.dokumentDelete }" @click.stop="showDeleteModal()">Löschen</li>
        </ul>
      </div>
    </div>

    <div class="col-2">
      <span>{{ doc.barcode }}</span>
    </div>

    <div class="col-2 text-end">
      <span>{{ dateConverter }}</span>
    </div>
  </div>

  <div v-if="currentCompLoading" class="spinner-border spinner-border-sm text-primary d-block mx-auto my-2" role="status">
    <span class="visually-hidden"></span>
  </div>

  <div v-else-if="doc.comps != null && doc.comps.length > 0 && showCompsArea" class="px-3 py-2">
    <div class="row m-0">
      <div class="col-4">
        <input class="form-check-input me-4" type="checkbox" value="" disabled />
        <font-awesome-icon icon="file" class="me-4 text-secondary" />

        <strong>Titel</strong>
      </div>
      <div class="col-3 fw-bold">Datei</div>
      <div class="col-1"></div>
      <div class="col-2 fw-bold">Autor</div>
      <div class="col-2 fw-bold">Datum</div>
    </div>
    <div v-for="comp in doc.comps.sort((a: Comp, b: Comp) => new Date(b.mdate).getTime() - new Date(a.mdate).getTime())" :key="comp.id">
      <ComponentComp :comp="comp" :archivid="archivid" :archivname="archivname || ''" :mandantid="mandantid || 0" />
    </div>
  </div>

  <ContextMenu ref="contextMenuRef" :items="contextMenuItems" :menuId="'doc-context-menu-' + doc.id" />

  <DokumentUpdateModal ref="updateModalRef" :doc="doc" :archivid="archivid" />
  <DokumentDeleteModal ref="deleteModalRef" :doc="doc" :archivid="archivid" @this-loading="setThisDocDeleteLoading" />

  <AddComponentModal ref="addCompModalRef" :archivid="archivid" :doc="doc" />
</template>

<script lang="ts" setup>
  //#region imports
  import { PropType, computed, onMounted, ref, watchEffect } from 'vue';
  import { useStore } from '@/store';
  import { archivHelperfunction } from '@/utils/ArchivFunctions';

  import { Doc } from '@/models/DocModels';
  import { Comp, GetCompsRequest } from '@/models/CompModels';
  import { Archiv, ArchivRechte } from '@/models/ArchivModels';

  import DokumentUpdateModal from './DokumentUpdateModal.vue';
  import DokumentDeleteModal from './DokumentDeleteModal.vue';
  import ComponentComp from '@/components/Archiv/Dokumente/Componenten/ComponentComp.vue';
  import AddComponentModal from './Componenten/AddComponentModal.vue';
  import ContextMenu from '@/components/ContextMenu.vue';

  // import { ActionTypes as CompActionTypes } from '@/store/modules/Comp/actions';
  import { ActionTypes as OrdnerActionTypes } from '@/store/modules/Ordner/actions';
  //#endregion

  const props = defineProps({
    doc: {
      type: Object as PropType<Doc>,
      // default: new Doc()
      required: true,
    },
    archivid: {
      type: Number as PropType<number>,
      required: true,
    },
    archivname: String,
    mandantid: Number,
  });

  const { getArchivrechte } = archivHelperfunction();

  const store = useStore();

  const archiv = computed(() => store.getters.archive.find((a: Archiv) => a.id == props.archivid));
  const rechte = computed(() => {
    if (archiv.value) {
      return getArchivrechte(archiv.value.archivrechte);
    } else {
      return new ArchivRechte();
    }
  });

  const deleteDocLoading = computed(() => store.getters.status.deleteDocLoading);

  const thisDocDeleteLoading = ref(false);
  function setThisDocDeleteLoading(loading: boolean) {
    thisDocDeleteLoading.value = loading;
  }

  const compLoading = computed(() => store.getters.status.compLoading);
  const compLoadingDocid = computed(() => store.getters.status.compLoadingDocid);
  const currentCompLoading = computed(() => compLoading.value && compLoadingDocid.value == props.doc.docid);

  const showCompsArea = ref(false);

  const dateConverter = computed(() => {
    if (props.doc.ddate != null) {
      const ddate = new Date(props.doc.ddate);
      return ('0' + ddate.getDate()).slice(-2) + '.' + ('0' + (ddate.getMonth() + 1)).slice(-2) + '.' + ddate.getFullYear();
    } else {
      return '';
    }
  });

  const getDocComponents = () => {
    if (props.archivid && props.doc.docid != '') {
      store.dispatch(OrdnerActionTypes.GetComps, new GetCompsRequest(props.archivid, props.doc.docid));
    }
  };

  const toggleCompsArea = () => {
    showCompsArea.value = !showCompsArea.value;

    if (showCompsArea.value && (!props.doc.comps || props.doc.comps.length == 0)) {
      getDocComponents();
    }
  };

  watchEffect(() => {
    if (props.doc.comps && props.doc.comps.length > 0) {
      showCompsArea.value = true;
    }
  });

  //#region ContextMenu
  const contextMenuRef = ref();
  function showContextMenu(event: MouseEvent) {
    event.stopPropagation();

    if (contextMenuRef.value) {
      contextMenuRef.value.showMenu(event.clientX, event.clientY);
    }
  }

  // const contextMenuItems = [
  //   { label: 'Bearbeiten', action: () => showUpdateModal() },
  //   { label: 'Löschen', action: () => showDeleteModal() },
  //   { label: 'Componente hinzufügen', action: () => showAddCompModal() },
  // ];

  const contextMenuItems = ref<Array<any>>([]);

  function setContextMenuItems() {
    // Edit Dokument
    contextMenuItems.value.push({ label: 'Bearbeiten', action: () => showUpdateModal(), disabled: !rechte.value.dokumentUpdate });

    // Create Component
    contextMenuItems.value.push({ label: 'Componente hinzufügen', action: () => showAddCompModal(), disabled: !rechte.value.dokumentCreate });

    // Delete Ordner
    contextMenuItems.value.push({ label: 'Löschen', action: () => showDeleteModal(), disabled: !rechte.value.dokumentDelete });
  }
  //#endregion

  const updateModalRef = ref();
  function showUpdateModal() {
    if (updateModalRef.value) {
      updateModalRef.value.showUpdateModal();
    }
  }

  const deleteModalRef = ref();
  function showDeleteModal() {
    if (deleteModalRef.value) {
      deleteModalRef.value.showDeleteModal();
    }
  }

  const addCompModalRef = ref();
  function showAddCompModal() {
    if (addCompModalRef.value) {
      addCompModalRef.value.showModal();
    }
  }

  onMounted(() => {
    setContextMenuItems();
  });
</script>

<style lang="scss" scoped>
  .doc-row:hover {
    background: #f8f9fa;
  }

  .doc-titel:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .doc-row .doc-menu-btn {
    visibility: hidden;
  }

  .doc-row:hover .doc-menu-btn {
    visibility: visible;
  }

  .doc-menu-btn:hover {
    background: rgb(221, 221, 221);
  }

  .doc-menu-btn:active {
    background: rgb(221, 221, 221) !important;
    border-color: transparent !important;
  }

  .doc-menu-btn:focus {
    background: rgb(221, 221, 221) !important;
    border-color: transparent !important;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-item:hover {
    cursor: pointer;
    background-color: rgb(221, 221, 221);
  }
</style>
