<template>
  <div class="row m-0 py-2 doc-row" @contextmenu.prevent="showContextMenu">
    <div class="col-4">
      <input class="form-check-input me-4" type="checkbox" value="" />

      <div v-if="(deleteCompLoading || loading) && thisLoading" class="spinner-border spinner-border-sm text-primary me-4"></div>
      <font-awesome-icon v-else-if="contenttype === 'pdf'" icon="file-pdf" class="me-4" />
      <font-awesome-icon v-else-if="contenttype === 'txt'" icon="file" class="me-4" />
      <font-awesome-icon v-else-if="contenttype === 'jpg' || contenttype === 'png' || contenttype === 'tif'" icon="file-image" class="me-4" />
      <font-awesome-icon v-else-if="contenttype === 'doc' || contenttype === 'docx'" icon="file-word" class="me-4" />
      <font-awesome-icon v-else-if="contenttype === 'xlsx'" icon="file-excel" class="me-4" />
      <font-awesome-icon v-else-if="contenttype === 'pptx'" icon="file-powerpoint" class="me-4" />
      <font-awesome-icon v-else icon="file" class="me-4" />
      <span @click="downloadComponent" class="doc-titel flex-fill me-4">{{ comp.titel }}</span>
    </div>

    <div class="col-3">
      {{ comp.filename }}
    </div>

    <div class="col-1">
      <div class="dropdown">
        <button type="button" class="btn btn-sm py-0 doc-menu-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" @click.stop>
          <font-awesome-icon icon="ellipsis-vertical" class="text-body-tertiary" />
        </button>

        <ul class="dropdown-menu m-0 p-0 rounded-0">
          <li class="dropdown-item" :class="{ disabled: !rechte.dokumentUpdate }" @click.stop="showUpdateModal()">Bearbeiten</li>
          <li class="dropdown-item" :class="{ disabled: !rechte.dokumentUpdate && !rechte.dokumentCreate }" @click.stop="showUploadFileModal()">
            Datei hochladen
          </li>
          <li class="dropdown-item" :class="{ disabled: !rechte.dokumentDelete }" @click.stop="showDeleteModal()">Löschen</li>
        </ul>
      </div>
    </div>

    <div class="col-2">
      {{ comp.autor }}
    </div>

    <div class="col-2">
      <span>{{ dateConverter }}</span>
    </div>
  </div>

  <!-- PDF Preview Modal -->
  <div
    class="modal fade p-0"
    :id="'ordnerDokumentPDFModal' + comp.id"
    tabindex="-1"
    :aria-labelledby="'ordnerDokumentPDFModal' + comp.id"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="ordnerDokumentPDFModalRef"
  >
    <div class="modal-dialog modal-dialog-centerd modal-xl my-0 h-100">
      <div class="modal-content h-100">
        <div class="modal-header py-0">
          <div class="w-100 d-flex justify-content-between me-3">
            <span class="modal-title my-auto" :id="'ordnerDokumentPDFModalLabel' + comp.id">
              {{ comp.titel }}
            </span>

            <!-- <button class="btn btn-outline-dark my-auto" @click="showSendEmailModal()">
              <font-awesome-icon icon="envelope" class="me-3"/>
              An private E-Mail senden
            </button> -->
          </div>
          <button type="button" class="btn-close" @click="closePdfModal()"></button>
        </div>

        <div class="modal-body p-0" :id="'ordnerDokumentPDFModalBody' + comp.id">
          <PDFViewer ref="pdfViewerRef" :docId="comp.id.toString()" :options="pdfViewerOptions" @sendDocAsEmail="showSendEmailModal()" />

          <div v-if="loading">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    </div>
  </div>

  <ContextMenu ref="contextMenuRef" :items="contextMenuItems" :menuId="'comp-context-menu-' + comp.id" />

  <ComponentUploadFileModal :compid="comp.compid" :archivid="archivid" ref="uploadFileModalRef" />
  <ComponentUpdateModal :comp="comp" :archivid="archivid" ref="updateModalRef" />
  <ComponentDeleteModal :comp="comp" :archivid="archivid" ref="deleteModalRef" @this-loading="setThisCompDeleteLoading" />
</template>

<script lang="ts" setup>
  //#region Imports
  import { PropType, computed, ref, onMounted } from 'vue';
  import { useStore } from '@/store';
  import { Modal } from 'bootstrap';
  import { archivHelperfunction } from '@/utils/ArchivFunctions';

  import PDFViewer from '@/components/PDFViewer/PDFViewer.vue';
  import ComponentDeleteModal from './ComponentDeleteModal.vue';
  import ComponentUpdateModal from './ComponentUpdateModal.vue';
  import ComponentUploadFileModal from './ComponentUploadFileModal.vue';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import ContextMenu from '@/components/ContextMenu.vue';

  import { PDFViewerOptions } from '@/components/PDFViewer/PDFViewerClasses';
  import { Comp, DownloadCompData } from '@/models/CompModels';
  import { Archiv, ArchivRechte } from '@/models/ArchivModels';

  import { ActionTypes as CompActionTypes } from '@/store/modules/Comp/actions';
  //#endregion

  const props = defineProps({
    comp: {
      type: Object as PropType<Comp>,
      required: true,
    },
    archivid: {
      type: Number,
      required: true,
    },
    archivname: {
      type: String,
      required: true,
    },
    mandantid: {
      type: Number,
      required: true,
    },
  });

  const { getArchivrechte } = archivHelperfunction();

  const store = useStore();

  const archiv = computed(() => store.getters.archive.find((a: Archiv) => a.id == props.archivid));
  const rechte = computed(() => {
    if (archiv.value) {
      return getArchivrechte(archiv.value.archivrechte);
    } else {
      return new ArchivRechte();
    }
  });

  const componente = computed(() => store.getters.componente);

  const loading = computed(() => store.getters.status.compDownloadLoading);
  const deleteCompLoading = computed(() => store.getters.status.deleteCompLoading);

  const thisLoading = ref(false);
  function setThisCompDeleteLoading(loading: boolean) {
    thisLoading.value = loading;
  }

  const pdfViewerOptions = ref(new PDFViewerOptions());

  const dateConverter = computed(() => {
    const mdate = new Date(props.comp.mdate);
    return (
      ('0' + mdate.getDate()).slice(-2) +
      '.' +
      ('0' + (mdate.getMonth() + 1)).slice(-2) +
      '.' +
      mdate.getFullYear() +
      ' ' +
      ('0' + (mdate.getHours() + 1)).slice(-2) +
      ':' +
      ('0' + (mdate.getMinutes() + 1)).slice(-2) +
      ':' +
      ('0' + (mdate.getSeconds() + 1)).slice(-2)
    );
  });

  const contenttype = computed(() => (props.comp.filename.length > 0 ? props.comp.filename.split('.')[1].toLowerCase() : ''));

  const pdfViewerRef = ref();
  const ordnerDokumentPDFModalRef = ref();
  const closeModal = () => {
    pdfViewerRef.value?.clearViewer();
    Modal.getInstance(ordnerDokumentPDFModalRef.value)?.hide();
  };
  const showModal = async () => {
    if (ordnerDokumentPDFModalRef.value) {
      new Modal(ordnerDokumentPDFModalRef.value).show();

      // await pdfViewerRef.value.loadPDF()
    }
  };

  function downloadComponent() {
    thisLoading.value = true;
    if ((props.comp.contenttype && props.comp.contenttype.toLowerCase() == '.pdf') || props.comp.filename.toLowerCase().includes('.pdf')) {
      showModal();
    }

    const data: DownloadCompData = {
      archivmandantid: props.mandantid,
      archivname: props.archivname,
      componente: props.comp,
    };

    store.dispatch(CompActionTypes.DownloadComp, data).then(() => {
      if ((componente.value.contenttype && componente.value.contenttype.toLowerCase() == '.pdf') || componente.value.filename.toLowerCase().includes('.pdf')) {
        const pdfUrl = base64PDFToBlobUrl(componente.value.file.filecontentAsBase64);

        setTimeout(() => {
          pdfViewerRef.value?.showPDF(pdfUrl, componente.value.filename);
        }, 50);
      } else {
        const url = base64PDFToBlobUrl(componente.value.file.filecontentAsBase64);
        saveInFileSystem(url, componente.value.filename);
      }

      thisLoading.value = false;
    });
  }

  function closePdfModal() {
    closeModal();

    // this.store.dispatch(ArchivActionTypes.ArchivClearComponentenCache, undefined);
  }

  async function saveInFileSystem(blobUrl: string, fileName: string) {
    const a = document.createElement('a');
    if (!a.click) {
      throw new Error('DownloadManager: "a.click()" is not supported.');
    }
    a.href = blobUrl;
    a.target = '_parent';
    if ('download' in a) {
      a.download = fileName;
    }
    (document.body || document.documentElement).append(a);
    a.click();
    a.remove();
  }

  function base64PDFToBlobUrl(base64: string) {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    const blob = new Blob([arr], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    return url;
  }

  //#region ContextMenu
  const contextMenuRef = ref();
  function showContextMenu(event: MouseEvent) {
    event.stopPropagation();

    if (contextMenuRef.value) {
      contextMenuRef.value.showMenu(event.clientX, event.clientY);
    }
  }

  // const contextMenuItems = [
  //   { label: 'Bearbeiten', action: () => showUpdateModal() },
  //   { label: 'Datei hochladen', action: () => showUploadFileModal() },
  //   { label: 'Löschen', action: () => showDeleteModal() },
  // ];

  const contextMenuItems = ref<Array<any>>([]);

  function setContextMenuItems() {
    // Edit Dokument
    contextMenuItems.value.push({ label: 'Bearbeiten', action: () => showUpdateModal(), disabled: !rechte.value.dokumentUpdate });

    // Datei hochladen
    contextMenuItems.value.push({
      label: 'Datei hochladen',
      action: () => showUploadFileModal(),
      disabled: !rechte.value.dokumentCreate && !rechte.value.dokumentUpdate,
    });

    // Delete Ordner
    contextMenuItems.value.push({ label: 'Löschen', action: () => showDeleteModal(), disabled: !rechte.value.dokumentDelete });
  }
  //#endregion

  //#region Upload File Modal
  const uploadFileModalRef = ref();
  function showUploadFileModal() {
    if (uploadFileModalRef.value) {
      uploadFileModalRef.value.showModal();
    }
  }
  //#endregion

  //#region Update
  const updateModalRef = ref();
  function showUpdateModal() {
    if (updateModalRef.value) {
      updateModalRef.value.showUpdateModal();
    }
  }
  //#endregion

  //#region Delete
  const deleteModalRef = ref();
  function showDeleteModal() {
    if (deleteModalRef.value) {
      deleteModalRef.value.showDeleteModal();
    }
  }
  //#endregion

  function showSendEmailModal() {
    console.log('not implemented yet.');
    // this.showError = false
    // this.showEmailModal()
  }

  // function closeSendEmailModal() {
  //   console.log('not implemented yet.');
  //   // this.privateEmail = this.personaldaten.email
  //   // this.closeEmailModal()
  // }

  // function sendmail() {
  //   console.log('not implemented yet.');
  //   // const data: SendDocAsMailRequest = {
  //   //   archivmandant: this.personaldaten.archivmandant,
  //   //   archivname: this.personaldaten.archivname,
  //   //   docguid: this.dokument.componente.docid.trim(),
  //   //   compguid: this.dokument.componente.compid.trim(),
  //   //   filename: this.dokument.componente.filename.trim(),
  //   //   archivtoken: this.archivUser.token,
  //   //   responseEmailAdresse: this.privateEmail
  //   // };

  //   // this.store.dispatch(PersonalActionTypes.SendDocAsMail, data)
  //   //   .then(() => {
  //   //     if (this.emailErrorMsg == null || this.emailErrorMsg == "") {
  //   //       this.closeEmailModal()
  //   //     }
  //   //     else {
  //   //       this.showError = true
  //   //     }
  //   //   });
  // }

  onMounted(async () => {
    const appsettings = await fetch('/appsettings.json');
    const config = await appsettings.json();

    pdfViewerOptions.value.defaultScale = config.pdfViewerDefaultScale;
    pdfViewerOptions.value.showEmailSendenOption = false;

    setContextMenuItems();
  });
</script>

<style lang="scss" scoped>
  .doc-row:hover {
    background: #f8f9fa;
  }

  .doc-titel:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .doc-row .doc-menu-btn {
    visibility: hidden;
  }

  .doc-row:hover .doc-menu-btn {
    visibility: visible;
  }

  .doc-menu-btn:hover {
    background: rgb(221, 221, 221);
  }

  .doc-menu-btn:active {
    background: rgb(221, 221, 221) !important;
    border-color: transparent !important;
  }

  .doc-menu-btn:focus {
    background: rgb(221, 221, 221) !important;
    border-color: transparent !important;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-item:hover {
    cursor: pointer;
    background-color: rgb(221, 221, 221);
  }

  .modal {
    height: 100% !important;
    /* overflow: hidden; */
  }
</style>
