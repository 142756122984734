<template>
  <div class="mb-2 fw-normal w-100 tree-node-element-text">
    <span @click="getSubtree()" class="tree-node-element"
      ><span class="pe-3" @click.stop="toggleSubtree()"
        ><font-awesome-icon icon="chevron-right" class="fa-sm" :class="showSubTree ? 'subtree' : 'nosubtree'" /></span
      ><span :class="isCurrentOrdner ? 'fw-bold' : 'opacity-75'" @click="goToOrdner()">{{ ordner.titel }}</span></span
    >
    <!-- <div v-if="ordnerSidebarLoading"> -->
    <div v-if="subTreeOfThisNodeLoading" class="spinner-border spinner-border-sm text-white d-block mx-auto" role="status">
      <span class="visually-hidden"></span>
    </div>

    <div v-else>
      <div v-show="showSubTree" class="ms-3">
        <OrdnerTreeNode v-for="ord in ordner.ordner" :key="ord.id" :ordner="ord" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, PropType, watchEffect } from 'vue';

  import { ActionTypes as OrdnerActionTypes } from '@/store/modules/Ordner/actions';

  import { GetOrdnerRequest, Ordner } from '@/models/OrdnerModels';
  import { GetDocsRequest } from '@/models/DocModels';

  import { useStore } from '@/store';
  import { useRoute } from 'vue-router';

  export default defineComponent({
    name: 'OrdnerTreeNode',
    props: {
      ordner: {
        type: Object as PropType<Ordner>,
        default: new Ordner(),
        required: true,
      },
    },

    setup(props) {
      const store = useStore();
      const route = useRoute();

      const currentOrdner = computed(() => store.getters.currentOrdner);
      const isCurrentOrdner = computed(() => currentOrdner.value[currentOrdner.value.length - 1] == props.ordner);
      const isInCurrentOrdnerPath = computed(() => currentOrdner.value.includes(props.ordner));
      const showSubTree = ref(false);
      const currentArchivId = computed(() => route.query.id?.toString());
      const ordnerSidebarLoading = computed(() => store.getters.status.ordnerSidebarLoading);
      const ordnerSidebarLoadingOrdid = computed(() => store.getters.status.ordnerSidebarLoadingOrdid);

      const subTreeOfThisNodeLoading = computed(() => ordnerSidebarLoading.value && ordnerSidebarLoadingOrdid.value == props.ordner.ordid);

      // show subtree by default if exists
      watchEffect(() => {
        if (props.ordner.ordner.length > 0) {
          showSubTree.value = true;
        }
      });

      // show subtree if it is in current Path
      watchEffect(() => {
        if (isInCurrentOrdnerPath.value) {
          showSubTree.value = true;
        }
      });

      const toggleSubtree = () => {
        showSubTree.value = !showSubTree.value;

        if (showSubTree.value && props.ordner.ordner.length == 0) {
          getSubtree();
        }
      };

      const getSubtree = async () => {
        if (props.ordner.ordner.length == 0) {
          await getSubfolders();
          showSubTree.value = true;
        } else {
          showSubTree.value = true;
        }
      };

      const getSubfolders = async () => {
        if (currentArchivId.value) {
          const data = new GetOrdnerRequest(parseInt(currentArchivId.value), props.ordner.ordid);
          await store.dispatch(OrdnerActionTypes.GetOrdnerFromSidebar, data);
        }

        return new Promise<void>((res, _rej) => res());
      };

      const getFolderDocs = () => {
        if (currentArchivId.value) {
          store.dispatch(OrdnerActionTypes.GetDocs, new GetDocsRequest(parseInt(currentArchivId.value), props.ordner.ordid));
        }

        return new Promise<void>((res, _rej) => res());
      };

      const goToOrdner = async () => {
        await getFolderDocs();
        store.dispatch(OrdnerActionTypes.SetCurrentOrdnerFromSidebar, props.ordner);
      };

      return {
        store,
        subTreeOfThisNodeLoading,
        isCurrentOrdner,
        showSubTree,
        toggleSubtree,
        getSubtree,
        isInCurrentOrdnerPath,
        currentArchivId,
        goToOrdner,
      };
    },
  });
</script>

<style scoped>
  .subtree {
    transition: transform 0.2s ease-in-out;
    transform: rotate(90deg);
  }

  .nosubtree {
    transition: transform 0.2s ease-in-out;
    transform: rotate(0deg);
  }

  .tree-node-element:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  .tree-node-element-text {
    overflow: hidden;
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
  }
</style>
